/* === Forms === */

label {
    display: block;
}

.search-submit {
    margin-top: 1em;
}

/* Not all types, but these are more common for themes. */
input[type="search"] {
    -webkit-appearance: none;
}

input,
select,
textarea {
    width: 100%;
    padding: 0.75em;
    border: 2px solid $color-border;
    border-color: $color-input;
    border-radius: 3px;
    max-width: 100%;
    &:focus {
        border-color: $color-dark;
        outline: none;
    }
}

.button,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: none;
    background: $color-accent;
    color: $color-white;
    font-size: 1.4rem;
    font-weight: $bold;
    border: none;
    padding: 1em 2em;
    cursor: pointer;
    transition: $site-transition;
    border-radius: $site-radius;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    &:hover,
    &:focus {
        background-color: lighten($color-accent, 10%);
        color: $color-white;
    } 
}
