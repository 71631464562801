/* ====== Simple Social Icons ====== */

.widget.simple-social-icons {
    .alignleft li {
        margin: 0 1rem 1rem 0 !important;
    }
    .alignright li {
        margin: 0 0 1rem 1rem !important;
    }
    ul li {
        a {
            line-height: 0;
        }
    }
}

// Inline.
.simple-social-icons {
    ul li {
        a,
        a:hover,
        a:focus {
            background-color: #edeeef;
            border-radius: 2px;
            color: #202428;
            border: 0px #ffffff solid;
            font-size: 19px;
            padding: 10px;
        }
        a:hover,
        a:focus {
            background-color: #202428;
            border-color: #ffffff;
            color: #ffffff;
        }
        a:focus {
            outline: 1px dotted #202428;
        }
    }
}


/* ====== Gravity Forms ====== */

.gform_heading,
.gform_body,
.gform_footer {
    clear: both;
}

.gform_heading {
    padding-bottom: 1em;
}

.gform_wrapper {
    .gform_fields {
        list-style: none;
        margin: 0 -1rem;
        padding: 0;
        li {
            list-style-type: none;
        }
    }
    .gfield_checkbox,
    .gfield_radio {
        list-style: none;
        padding: 0;
    }
}

.ginput_container {
    span {
        margin-bottom: 1rem;
    }
    input,
    select,
    textarea {
        width: 100%;
        border-radius: 2px;
    }
    input,
    textarea {
        margin: 0;
    }
    select {
        height: 4.4rem;
        -webkit-appearance: menulist-button;
    }
}

.gfield_label {
    display: inline-block;
    padding-bottom: 0.5em;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2rem;
    &:empty {
        display: none;
    }
    .left_label & {
        float: left;
    }
    .right_label & {
        float: right;
    }
}

.gfield_required {
    padding-left: 0.1em;
}

.gfield_checkbox,
.gfield_radio {
    li {
        width: 100%;
    }
    input {
        width: auto;
        float: left;
        margin-right: 1rem;
    }
    label {
        float: left;
        line-height: 1;
    }
}

.gsection {
    margin: 1rem 0;
    h2 {
        border-bottom: $site-border;
        padding-bottom: 1rem;
    }
}

.clear-multi {
    display: flex;
    align-items: flex-start;
    >div {
        margin-right: 1rem;
        i {
            display: none;
        }
    }
}

.gf_progressbar_wrapper {
    padding-bottom: 15px;
}

.gf_progressbar {
    background: gray;
}

.gf_progressbar_percentage {
    background: red;
    padding: 5px 1rem;
    text-align: right;
}

.gfield_time_hour,
.gfield_time_minute,
.gfield_time_ampm {
    display: inline;
    display: inline-block;
}

.gfield_time_hour,
.gfield_time_minute,
.gfield_time_ampm {
    input {
        width: 5em;
    }
    select {
        width: 4em;
    }
    label {
        display: none;
    }
}

.ginput_container_date {
    position: relative;
    input.datepicker.medium {
        width: 100%;
    }
    img {
        position: absolute;
        right: 1.4rem;
        top: 1.4rem;
    }
}

.ui-datepicker {
    background-color: $color-white;
    font-size: 1.2rem;
    .ui-datepicker-header {
        padding: 1em;
        border-left: $site-border;
        border-right: $site-border;
        select {
            width: 6rem;
            margin-left: 1rem;
        }
    }
    .ui-datepicker-prev,
    .ui-datepicker-next {
        float: left;
        margin-right: 1rem;
        font-weight: bold;
        cursor: pointer;
    }
    table {
        font-size: 1.35rem;
        margin-bottom: 0;
    }
    th,
    td {
        padding: 0.5em 0.75em;
        text-align: center;
    }
    a {
        text-decoration: none;
        font-weight: bold;
    }
}

.validation_error {
    padding-bottom: 2em;
    color: $color-white;
}

.gfield {
    width: 100%;
    list-style-type: none;
    clear: both;
    overflow: auto;
    padding: 0 1rem 1.5rem;
    margin: 0;
    .validation_message {
        margin-top: 0.5rem;
        font-size: 1rem;
        position: absolute;
        color: pink;
    }
    li {
        list-style-type: none;
    }
}

.gform_validation_container {
    display: none;
}

.gform_footer {
    .gform_button {
        width: auto;
    }
}

.ginput_full {
    display: block;
    input,
    select,
    textarea {
        margin-bottom: 5px;
        width: 100%;
    }
    label {
        display: block;
    }
}

.ginput_left {
    float: left;
    padding-right: 1rem;
    input,
    select,
    textarea {
        margin-bottom: 5px;
        width: 100%;
    }
    label {
        display: block;
    }
}

.ginput_right {
    float: right;
    input,
    select,
    textarea {
        margin-bottom: 5px;
        width: 100%;
    }
    label {
        display: block;
    }
}

.gf_left_half {
    clear: none;
    float: left;
    padding-right: 1rem;
    padding-bottom: 1rem;
}

.gf_right_half {
    clear: none;
    float: right;
    padding-bottom: 1rem;
}

.gf_left_third {
    clear: none;
    float: left;
    padding-right: 1rem;
    padding-bottom: 1rem;
}

.gf_middle_third {
    clear: none;
    float: left;
    padding-right: 1rem;
    padding-bottom: 1rem;
}

.gf_right_third {
    clear: none;
    float: right;
    padding-bottom: 1rem;
}

.gf_first_quarter {
    clear: none;
    float: left;
    padding-right: 1rem;
    padding-bottom: 1rem;
}

.gf_second_quarter {
    clear: none;
    float: left;
    padding-right: 1rem;
    padding-bottom: 1rem;
}

.gf_third_quarter {
    clear: none;
    float: left;
    padding-bottom: 1rem;
}

.gf_fourth_quarter {
    clear: none;
    float: right;
    padding-bottom: 1rem;
}

li {
    .gf_list_2col & {
        float: left;
        padding-bottom: 1rem;
    }
    .gf_list_3col & {
        float: left;
        padding-bottom: 1rem;
    }
    .gf_list_4col & {
        float: left;
        padding-bottom: 1rem;
    }
    .gf_list_5col & {
        float: left;
        padding-bottom: 1rem;
    }
    .gf_list_inline & {
        display: inline-block;
        padding-right: 1rem;
        padding-bottom: 1rem;
    }
}

@include breakpoint(xs) {
    .ginput_container {
        input,
        select,
        textarea {
            &.large {
                width: 100%;
            }
            &.medium {
                width: 66%;
            }
            &.small {
                width: 33%;
            }
        }
    }
    .ginput_left,
    .ginput_right {
        width: 50%;
    }
    label.gfield_label {
        .left_label & {
            width: 25%;
        }
        .right_label & {
            width: 25%;
        }
    }
    li {
        &.gf_left_half,
        &.gf_right_half {
            width: 50%;
        }
        &.gf_left_third,
        &.gf_right_third {
            width: 33%;
        }
        &.gf_middle_third {
            width: 34%;
        }
        &.gf_first_quarter,
        &.gf_second_quarter,
        &.gf_third_quarter,
        &.gf_fourth_quarter {
            width: 25%;
        }
        .gf_list_2col & {
            width: 50%;
        }
        .gf_list_3col & {
            width: 33%;
        }
        .gf_list_4col & {
            width: 25%;
        }
        .gf_list_5col & {
            width: 20%;
        }
    }
}


/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    &:focus {
        outline: none;
    }
    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider {
    .slick-track,
    .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    &:before {
        display: table;
        content: '';
    }
    &:after {
        display: table;
        content: '';
        clear: both;
    }
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide {
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }
    &.dragging img {
        pointer-events: none;
    }
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}