.home {
    .site-inner {
        padding: 0;
        position: relative;
    }
}


/* === Hero === */

.hero {
    background-image: url('/wp-content/uploads/2017/06/perth-bus-coach-charter.jpg');
    background-size: cover;
    background-position: center;
    color: $color-white;
    padding: 20vw 0;
    text-align: center;
    position: relative;
    @include clearfix;
    @include breakpoint(sm) {
        padding: 20vh 0;
        background-attachment: fixed;
    }
    &:before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #111;
        z-index: 1;
        opacity: 0.618;
    }
    .wrap {
        position: relative;
        z-index: 1;
        text-align: center;
        justify-content: center;
        @include breakpoint(md) {
            flex-wrap: wrap;
            display: block;
            max-width: map-get($breakpoints, md);
        }
    }
    h1 {
        margin-bottom: 0.382em;
        color: $color-white;
        width: 100%;
        font-size: 1.8em;
        @include breakpoint(sm) {
            font-size: 2.2em;
        }
    }
}

.wp-custom-header {
    position: fixed;
    z-index: -1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    transition: all 5s ease;
    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: auto;
        height: auto;
        min-height: 100%;
        min-width: 100%;
        max-width: 100%;
        max-height: none;
        object-fit: cover;
    }
}


/* === Benefits === */

.benefits {
    background-color: $color-border;
    @include scale(padding-top, padding-bottom);
    @include clearfix;
    .one-fourth {
        position: relative;
        padding-left: 4rem;
        padding-bottom: 3rem;
        @include breakpoint(sm) {
            padding-bottom: 0;
        }
        &:last-of-type {
            padding-bottom: 0.5rem;
        }
    }
    i {
        font-size: 4rem;
        position: absolute;
        top: 0;
        left: -0.3rem;
    }
    strong {
        font-size: 1.6rem;
        display: block;
        text-transform: uppercase;
    }
    small {
        display: block;
        line-height: 1;
        font-size: 1.4rem;
    }
}


/* === Services === */

.services {
    background-color: $color-white;
    text-align: center;
    @include scale(padding-top, padding-bottom);
    @include clearfix;
    .site-inner & .wrap {
        display: block;
    }
    h2 {
        margin-bottom: 0;
        text-transform: capitalize;
        span {
            color: $color-accent;
        }
        +p {
            max-width: map-get($breakpoints, xs);
            margin: 0 auto;
            line-height: 1.382;
            @include scale(margin-bottom);
        }
        &:after {
            content: " ";
            display: block;
            width: 10rem;
            height: 0.3rem;
            background-color: $color-accent;
            margin: 0.82em auto;
        }
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    li {
        position: relative;
        display: flex;
        align-items: stretch;
        justify-content: center;
        margin-bottom: 5vw;
        @include breakpoint(xs) {
            width: 48.5%;
            margin-bottom: 3%;
        }
        @include breakpoint(md) {
            width: 31.3333333333333%;
        }
        &:last-of-type {
            margin-bottom: 0;
            @include breakpoint(xs) {
                margin-bottom: 0;
            }
            @include breakpoint(md) {
                margin-bottom: 3%;
            }
        }
        a {
            display: block;
            width: 100%;
            text-decoration: none;
            &:after {
                content: " ";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                box-shadow: inset 0 -15rem 10rem -10rem rgba(0, 0, 0, 0.82);
                transition: box-shadow 0.2s ease;
            }
            &:hover:after {
                box-shadow: inset 0 -15rem 15rem -7.5rem rgba(0, 0, 0, 1);
            }
        }
        strong {
            position: absolute;
            z-index: 1;
            bottom: 0;
            pointer-events: none;
            color: $color-white;
            text-transform: uppercase;
            font-size: 1.4rem;
            padding: 1em;
            width: 100%;
            display: block;
        }
    }
}

/* === Features === */

.features {
    background-color: $color-border;
    @include scale(padding-top, padding-bottom);
    @include clearfix;
    h2 {
        margin: 0 auto;
        text-transform: capitalize;
        text-align: center;
        b {
            color: $color-accent;
        }
        &:after {
            content: " ";
            display: block;
            width: 10rem;
            height: 0.3rem;
            background-color: $color-accent;
            margin: 0.82em auto;
        }
        +p {
            max-width: map-get($breakpoints, xs);
            margin: 0 auto;
            line-height: 1.382;
            text-align: center;
            @include scale(margin-bottom);
        }
    }
}

.tab-list {
    list-style: none;
    position: relative;
    width: 100%;
    display: flex;
    align-items: stretch;
    margin: 0;
}

.tab-item {
    flex: 1;
    width: 25%;
    margin: 0;
    >a {
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        font-size: 1.4rem;
        font-weight: $bold;
        height: 100%;
        background: $color-light;
        border: $site-border;
        padding: 1.618em 0;
    }
    &.is-active {
        a {
            background: $color-white;
            border-bottom-color: $color-white;
        }
    }
    span {
        display: none;
        @include breakpoint(xs) {
            display: inline-block;
        }
    }
    i {
        @include breakpoint(xs) {
            display: none;
        }
        @include breakpoint(md) {
            display: inline-block;
            position: absolute;
            margin-left: -1.1em;
        }
    }
}

.tab-content {
    background-color: $color-white;
    border: $site-border;
    border-top: none;
    @include scale(padding);
    &[aria-hidden="true"] {
        left: -999em;
        position: absolute;
        top: -999em;
    }
    &.is-active {
        position: static;
    }
}

.chris {
    .image {
        height: 16rem;
        width: 16rem;
        overflow: hidden;
        border-radius: 10rem;
        margin-right: 6rem;
        margin-bottom: 5vw;
        border: 1rem solid $color-border;
        display: block;
        @include breakpoint(sm) {
            float: left;
            margin-bottom: 0;
        }
    }
    p {
        margin-bottom: 0;
        margin-top: 1em;
    }
    img {
        margin-top: -1rem;
    }
}


/* === About === */

.about {
    background-color: $color-white;
    @include scale(padding-top,padding-bottom,margin-bottom);
    @include clearfix;
}


/* === Testimonials === */

.testimonials {
    background-image: url('/wp-content/uploads/2017/06/perth-bus-coach-charter.jpg');
    background-position: center;
    background-size: cover;
    position: relative;
    color: $color-white;
    @include scale(padding-top, padding-bottom);
    @include clearfix;
    @include breakpoint(sm) {
        background-attachment: fixed;
    }
    &:before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #111;
        z-index: 1;
        opacity: 0.618;
    }
    .wrap {
        align-items: center;
        position: relative;
        z-index: 2;
        @include scale(padding-top, padding-bottom);
    }
    .one-half {
        margin-bottom: 7.5vw;
        display: block;
        @include breakpoint(sm) {
            margin-bottom: 0;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    i {
        color: orange;
    }
    blockquote {
        padding-left: 0;
        border: none;
    }
    b {
        &:before {
            content: "-";
            padding-right: 0.5em;
        }
    }
}


/* === News === */

.news {
    background-color: $color-white;
    @include scale(padding-top, padding-bottom);
    @include clearfix;
    .wrap {
        align-items: center;
    }
    h2 {
        margin: 0 auto;
        text-align: center;
        text-transform: capitalize;
        max-width: 90%;
        span {
            color: $color-accent;
        }
        &:after {
            content: " ";
            display: block;
            width: 10rem;
            height: 0.3rem;
            background-color: $color-accent;
            margin: 0.82em auto;
        }
        +p {
            width: 90%;
            max-width: map-get($breakpoints, xs);
            margin: 0 auto;
            line-height: 1.382;
            text-align: center;
            @include scale(margin-bottom);
        }
    }
    .entry {
        padding: 0;
        background-color: $color-light;
        margin: 0 1em;
    }
    .entry-header,
    .entry-summary,
    .entry-footer {
        padding: 0 3rem;
    }
    .entry-header {
        font-size: 1.2rem;
        h3 {
            font-size: 2rem;
        }
    }
    .entry-summary {
        font-size: 1.6rem;
    }
    .entry-footer {
        border-top: $site-border;
        padding-top: 2rem;
        padding-bottom: 3rem;
    }   
}