/**
 * Header
 * --------------------------------------------------------------------------- */

.site-header {
    background-color: $color-white;
    @include breakpoint(md) {
        background-color: $color-border;
    }
    .wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        @include breakpoint(md) {
            flex-wrap: nowrap;
        }
    }
}

.site-branding {
    max-width: 14.6rem;
    position: relative;
    z-index: 99;
    padding: 1rem 2rem 1rem 0;
    @include breakpoint(md) {
        max-width: 25rem;
        padding: 1.618rem 4rem 1.618rem 0;
    }
    &::before {
        content: " ";
        display: block;
        height: 100%;
        width: 500rem;
        background-color: $color-white;
        position: absolute;
        z-index: -1;
        right: 0;
        top: 0;
        //transform: skewX(-12deg);
    }
}

/* ==== Header Right ==== */

.contact {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    margin: 0 -5vw;
    padding: 1.2rem 5vw 1rem;
    background-color: $color-dark;
    @include breakpoint(md) {
        width: calc(100% - 25rem);
        margin: 0;
        padding: 0;
        background-color: transparent;
        justify-content: flex-end;
    }
    a {
        position: relative;
        color: $color-white;
        text-decoration: none;
        padding-left: 2em;
        @include breakpoint(md) {
            position: relative;
            line-height: 1.236;
            margin-left: 1em;
            width: auto;
            color: $color-dark;
        }
        @include breakpoint(lg) {
            margin-left: 3em;
        }
        &.location {
            display: none;
            @include breakpoint(xs) {
                display: block;
            }
        }
        i {
            float: left;
            font-size: 1.8em;
            margin-right: 0.1em;
            color: $color-accent;
            position: absolute;
            left: 0;
        }
        small {
            text-transform: uppercase;
            font-size: 0.8rem;
            display: block;
            line-height: 1;
            font-weight: bold;
            @include breakpoint(md) {
                font-size: 1rem;
            }
        }
        span {
            font-size: 1.6rem;
            text-transform: uppercase;
            display: block;
            font-weight: bold;
            line-height: 1.2;
            @include breakpoint(md) {
                font-size: 2rem;
            }
        }
    }
}