/**
 * Menus
 * --------------------------------------------------------------------------- */

button.menu-toggle {
    padding: 2.6rem 0;
    cursor: pointer;
    background-color: transparent;
    display: block;
    position: absolute;
    top: 0;
    right: 5vw;
    line-height: 1.33333333;
    color: $color-body;
    vertical-align: middle;
    &:hover {
        background: none;
        outline: none;
        box-shadow: none;
    }
    &:focus {
        outline: $site-border;
        background: none;
    }
    @include breakpoint(md) {
        display: none;
    }
    span {
        display: inline-block;
        position: relative;
        height: 2.5px;
        background: $color-body;
        transition: background 0s 0.25s;
        width: 2.5rem;
        float: right;
        margin-left: 0.236em;
        margin-top: 7px;
        border-radius: $site-radius;
        &::before,
        &::after {
            position: absolute;
            display: block;
            left: 0;
            width: 100%;
            height: 2.5px;
            background-color: $color-body;
            border-radius: $site-radius;
            content: " ";
        }
        &::before {
            top: -7px;
            transition: top 0.25s 0.25s, transform 0.25s 0s;
        }
        &::after {
            bottom: -7px;
            transition: bottom 0.25s 0.25s, transform 0.25s 0s;
        }
    }
    &.activated span {
        background: none;
        &::before {
            top: 0;
            transform: rotate(45deg);
            transition: top 0.25s 0s, transform 0.25s 0.25s;
        }
        &::after {
            bottom: 0;
            transform: rotate(-45deg);
            transition: bottom 0.25s 0s, transform 0.25s 0.25s;
        }
    }
}

.sub-menu-toggle {
    position: absolute;
    right: 0;
    top: 0;
    background: transparent;
    box-shadow: inset 0 0 0 99rem rgba($color-white, 0.1);
    color: $color-white;
    padding: 1em;
    margin: 0.5em 0;
    z-index: 1;
    border: none;
    font-size: 1em;
    @include breakpoint(md) {
        display: none;
    }
    &:hover,
    &:focus {
        background: none;
    }
    &:before {
        content: "";
        display: block;
        margin: 0.2rem 0 -0.2rem;
        border: 0.4rem solid transparent;
        border-top-color: $color-white;
    }
    &.activated {
        &:before {
            transform: rotate(180deg);
            margin: -0.2rem 0 0.2rem;
        }
    }
}

.menu {
    font-weight: $bolder;
    font-size: 1.4rem;
    text-transform: uppercase;
    ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin: 0;
        padding: 0;
        @include breakpoint(md) {
            flex-wrap: nowrap;
            display: flex;
        }
        ul {
            display: none;
            @include clearfix;
            @include breakpoint(md) {
                background-color: darken( $color-dark, 5% );
                position: absolute;
                padding: 2rem;
                width: 100%;
                top: 100%;
                left: 0;
            }
            li {
                clear: both;
                @include clearfix;
                @include breakpoint(md) {
                    width: 33.333333333333333%;
                    clear: none;
                    display: block;
                    float: left;
                }
            }
            ul {
                left: 100%;
                top: calc(100% - 1px);
            }
        }
    }
    li {
        width: 100%;
        margin-bottom: 0;
        clear: both;
        list-style-type: none;
        position: relative;
        @include breakpoint(md) {
            width: auto;
            clear: none;
            position: static;
        }
        &.menu-item-has-children {
            > a span:after {
                @include breakpoint(md) {
                    content: 'arrow_drop_down';
                    font-family: 'Material Icons';
                    line-height: 1;
                    text-transform: none;
                    display: inline-block;
                    white-space: nowrap;
                    -webkit-font-feature-settings: 'liga';
                }
            }
        }
        &.right {
            margin-left: auto;
            a {
                @include breakpoint(md) {
                    padding-right: 0;
                }
            }
        }
        li {
            a {
                padding-left: 1em;
                @include breakpoint(md) {
                    padding: 1em;
                }
            }
            &:first-of-type {
                a {
                    margin-top: 0;
                }
            }
            li {
                a {
                    padding-left: 2em;
                    @include breakpoint(md) {
                        padding: 1em;
                    }
                }
                li {
                    a {
                        padding-left: 3em;
                        @include breakpoint(md) {
                            padding: 1em;
                        }
                    }
                }
            }
        }
    }
    a {
        display: block;
        color: $color-body;
        text-decoration: none;
        padding: 1em 0;
        @include breakpoint(md) {
            padding: 1em;
            width: 100%;
        }
        @include breakpoint(xl) {
            padding: 1.5em;
            width: 100%;
        }
        &:hover {
            color: $color-accent;
        }
    }
}

/* ====== Primary Menu ====== */

.menu-primary {
    background-color: darken( $color-dark, 10% );
    display: none;
    position: absolute;
    z-index: 9;
    width: 100%;
    @include breakpoint(md) {
        background-color: $color-dark;
        border-top: $site-border;
        position: relative;
        display: block;
        z-index: 9;
    }
    .wrap {
        position: relative;
        justify-content: flex-start;
        padding: 5vw 0;
        @include breakpoint(md) {
            padding: 0;
        }
        > ul {
            width: 100%;
            > li:first-of-type {
                > a {
                    @include breakpoint(md) {
                        padding-left: 0;
                    }
                }
            }
        }
    }
    li a {
        color: $color-white;
    }
}


/* ====== Footer Menu ====== */

.menu-footer {
    background-color: $color-light;
    font-size: 1.2rem;
    padding-top: 4rem;
    ul {
        margin: 0 auto;
        padding-bottom: 1.5em;
        justify-content: center;
        ul {
            display: none;
        }
    }
    li {
        width: auto;
        margin: 0 1em;
        a {
            padding: 0.382em;
        }
    }
}