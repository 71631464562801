/**
 * Sidebars and widgets
 * --------------------------------------------------------------------------- */


/* ====== General ====== */

.sidebar {
    font-size: 1.6rem;
    ul {
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.widget {
    background-color: $color-white;
    padding: 3rem;
    @include scale(margin-top);
    @include breakpoint(xs) {
        padding: 4rem;
    }
    &:first-of-type {
        @include breakpoint(lg) {
            margin-top: 0;
        }
    }
}

.widget-title {
    font-size: 2rem;
}


/* ====== Specific ====== */

.sidebar-primary {
    .widget {
        &:first-of-type {
            background-color: $color-dark;
            color: $color-white;
            h3,
            .widget-title {
                color: $color-white;
                margin-bottom: 0.5em;
            }
            input {
                width: 100%;
                border: none;
                border-radius: 2px;
            }
            button,
            input[type="button"],
            input[type="submit"] {
                background-color: $color-accent;
                width: 100%;
                max-width: map-get($breakpoints, xs);
                &:hover {
                    color: $color-body;
                    background-color: $color-white;
                }
            }
            .gfield_label {
                display: none;
            }
            .gform_description {
                font-size: 1.4rem;
                line-height: 1.382;
                display: block;
            }
        }
        .post-date {
            display: block;
            font-size: 1.2rem;
            line-height: 1;
        }
    }
    .social-icons {
        display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
        text-align: left;
		margin: -1rem 0 0 -1rem;
        a {
			background-color: $color-border;
			border-radius: 3px;
            position: relative;
            height: 4rem;
            width: 4rem;
			margin: 1rem 0 0 1rem;
			display: block;
			padding: 0.5rem;
			&:hover {
				background-color: $color-dark;
				svg {
					fill: $color-white;
				}
			}
        }
        svg {
			display: block;
            height: 100%;
            width: 100%;
        }
    }
}