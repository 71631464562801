/**
 * Media
 * --------------------------------------------------------------------------- */


/* === Images === */

img {
    max-width: 100%;
    height: auto;
}

a img {}


/* === WP editor image sizes === */

img.size-thumbnail {}

img.size-medium {}

img.size-large {}

img.size-full {}


/* === Thumbnails === */

.thumbnail {}


/* === Captions === */

.wp-caption {}

.wp-caption img {}

.wp-caption-text {}

.wp-caption-text a {}

.wp-caption-text a:hover {}


/* === Embeds === */

object {}

embed {}

iframe {}

video {}


/* === Alignement (WP classes) === */

.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img {
    &.alignright {
        float: right;
        margin: 5px 0 20px 20px;
    }
    &.alignnone {
        margin: 5px 20px 20px 0;
    }
    &.alignleft {
        float: left;
        margin: 5px 20px 20px 0;
    }
    &.aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto
    }
}

p img {
    &.alignnone,
    &.alignleft,
    &.alignright {
        margin-bottom: 0;
    }
}

.wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%;
    /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
    &.alignnone {
        margin: 5px 20px 20px 0;
    }
    &.alignleft {
        margin: 5px 20px 20px 0;
    }
    &.alignright {
        margin: 5px 0 20px 20px;
    }
    img {
        border: 0 none;
        height: auto;
        margin: 0;
        max-width: 98.5%;
        padding: 0;
        width: auto;
    }
    p.wp-caption-text {
        font-size: 11px;
        line-height: 17px;
        margin: 0;
        padding: 0 4px 5px;
    }
}