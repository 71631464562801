/* ====== POSTS ====== */

.entry {
    background-color: $color-white;
    @include scale(padding, margin-top);
    &:first-of-type {
        margin-top: 0;
    }
    >a {
        display: block;
    }
}

.hentry {}

.sticky {}


/* === Post Header === */

.entry-header {}

.entry-title {}

.entry-title a {
    color: $color-body;
    text-decoration: none;
    &:hover {
        color: $color-accent;
    }
}

.entry-byline {
    margin-bottom: 1em;
}

.entry-author {
    text-transform: capitalize;
}


/* === Post content and excerpts === */

.entry-content {
    @include clearfix;
    ul,
    ol {
        padding-left: 1em;
    }
    ul li {
        list-style-type: disc;
    }
    ol li {
        list-style-type: decimal;
    }
    li {
        margin-bottom: 1rem;
        li {
            list-style-type: circle;
        }
    }
}

.entry-summary {}


/* === Post Footer === */

.entry-footer {
    font-size: 1.6rem;
    border-top: $site-border;
    padding-top: 1em;
    .page & {
        padding: 0;
        border: none;
    }
}

.entry-meta {}

.entry-meta a {}

.entry-meta a:hover {}

.entry-meta .published {}

.entry-meta .category {}

.entry-meta .post_tag {}


/* === Asides === */

.format-aside {}

.format-aside .entry-content a.permalink {}


/* === Chats === */

.format-chat {}

.format-chat .chat-row {}

.format-chat .chat-text {}

.format-chat cite {}

.format-chat .chat-speaker-1 {}

.format-chat .chat-speaker-2 {}

.format-chat .chat-speaker-3 {}

.format-chat .chat-speaker-4 {}

.format-chat .chat-speaker-5 {}

.format-chat .chat-speaker-6 {}

.format-chat .chat-speaker-7 {}

.format-chat .chat-speaker-8 {}

.format-chat .chat-speaker-9 {}


/* === Links === */

.format-link {}

.format-link .entry-title {}

.format-link .entry-title a {}

.format-link .entry-title a:hover {}

.format-link .entry-title a .meta-nav {}


/* === Galleries === */

.format-gallery {}

.format-gallery .image-count {}


/* === Quotes === */

.format-quote {}

.format-quote blockquote {}

.format-quote blockquote cite {}


/* === Status updates === */

.format-status .format-status .avatar {}


/* === Audio and Video === */

.format-video {}

.format-audio {}