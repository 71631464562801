/**
 * Comments Template
 * --------------------------------------------------------------------------- */

/* ====== COMMENTS ======= */

.comments {
    background-color: $color-white;
    @include scale(padding, margin-top);

    ul,
    ol {
        list-style-type: none;
        padding: 0;

        ul,
        ol {
            @include scale(padding-left);
        }
    }
}

/* === Comments closed message === */

.comments-closed {}

.comments-closed.pings-open {}


/* === Comment list === */

ol.comment-list {}

ol.comment-list ol.children {}


/* === Individual comments */

.comment {
    font-size: 1.6rem;
    @include scale(margin-top, margin-bottom);
}
.ping {}
.bypostauthor {}


/* === Header === */

.comment-header {}


/* === Avatars === */

.avatar {
    border-radius: 99px;
    margin-right: 1em;
    float: left;
}

a .avatar {}
a:hover .avatar {}


/* === Meta === */

.comment-author {
    font-style: normal;
}

.comment-meta {
    font-size: 1.6rem;
}

.comment-meta .published {}


/* === Comment text/content === */

.comment-content {
    padding: 1em 0;
}


/* === Reply link === */

.comment-reply-link {
    margin: -2em 0 2em;
    display: block;
}

.comment-reply-link:hover {}


/* === Comments pagination === */

.comments-nav {}

.comments-nav a {}

.comments-nav a:hover {}

.comments-nav .page-numbers {}


/* ====== RESPOND (COMMENT FORM) ====== */

.comment-respond {
    background-color: $color-white;
    @include scale(padding,margin-top);
    ul &,
    ol & {
        padding: 0;
    }
    .log-in-out {}
    label {}
    label .required {}
    input {
        width: 100%;
    }
    input[type="text"] {}
    input[type="text"]:focus {}
    input[type="url"] {}
    input[type="url"]:focus {}
    input[type="email"] {}
    input[type="email"]:focus {}
    input[type="submit"] {
        width: auto;
    }
    input[type="submit"]:focus {}
    input[type="submit"]:hover {}
    textarea {
        width: 100%;
    }
    textarea:focus {}
}

.comment-form-author,
.comment-form-email,
.comment-form-url {
    @include breakpoint(sm) {
        width: 50%;
    }
}
