/**
 * Content
 * --------------------------------------------------------------------------- */

 .entry-content {
	 p {
		 text-align: left !important;
	 }
	 span {
		 color: $color-body !important;
	 }
 }

/* ====== BREADCRUMBS ====== */

.breadcrumbs {
	background-color: $color-white;
	font-size: 1.6rem;
	padding: 1em;
	border-bottom: $site-border;
	display: flex;
	flex-wrap: wrap;
	@include scale(padding-left,padding-right);

	ul {
		display: flex;
		flex-wrap: wrap;
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			margin-right: 0.2em;
			margin-bottom: 0;

			&:first-of-type {
				margin-left: 0;
			}

			&:last-of-type {

				&:after {
					display: none;
				}
			}

			&:after {
				content: "/";
				margin-left: 0.2em;
			}
		}
	}
}


/* === Single post navigation (between different posts) === */

.loop-nav {
	font-size: 1.6rem;
	background-color: $color-white;
	@include scale(padding,margin-top);
}


/* === Archive-type pages === */

.archive-header {
	background-color: $color-white;
	@include scale(padding,margin-bottom);
	p {
		margin: 0.5em 0 0;
		font-size: 1.6rem;
	}
}

.archive-title {
	margin: 0;
	font-size: 2rem;
}

.pagination {
	background-color: $color-white;
	@include scale(padding, margin-top);
}



/* === Contact Page === */

.page-contact-us {
	.entry-content {
		a {
			text-transform: uppercase;
			font-weight: bold;
			text-decoration: none;
			font-size: 1.4rem;
			margin-right: 2em;
			margin-bottom: 2em;
			display: block;
			float: left;
			word-break: break-all;
			color: $color-dark;
		}
		i {
			float: left;
			color: $color-accent;
			margin-right: 0.1em;
		}
	}
}