/**
 * Footer
 * --------------------------------------------------------------------------- */

.site-footer {
    background-color: $color-white;
    font-size: 1.6rem;
    h4 {
        font-size: 1.6rem;
        text-transform: uppercase;
    }
    .business {
        display: flex;
        flex-wrap: wrap;
        @include scale(padding-top, padding-bottom);
        >div {
            position: relative;
            margin-bottom: 7.5vw;
            @include breakpoint(sm) {
                flex: 1;
                margin-left: 4rem;
                margin-bottom: 0;
                &:first-of-type {
                    margin-left: 0;
                }
            }
            &.contact-us {
                margin-bottom: 0;
            }
        }
    }
    .logo {
        padding-right: 4rem;
        &:before {
            content: " ";
            display: none;
            position: absolute;
            background-color: $color-border;
            @include breakpoint(sm) {
                height: calc(100% + 12rem);
                width: 100vw;
                left: 100%;
                top: -6rem;
                display: block;
            }
        }
        img {
            max-width: 30rem;
        }
    }
    .credit {
        background-color: $color-light;
        margin: 0;
        padding: 0 5vw;
        text-align: center;
        font-size: 1.4rem;
        padding-bottom: 4rem;
    }
}

.contact-us {
    font-weight: bold;
    i {
        width: 1em;
        margin-right: 0.2em;
        display: inline-block;
        float: left;
        color: $color-accent;
    }
    a {
        text-decoration: none;
        margin-bottom: 1em;
        display: inline-block;
        color: $color-dark;
        &:hover {
            color: $color-grey;
        }
    }
    span {
        display: inline-block;
    }
}

.social {
    margin-top: 1em;
    a {
        background-image: url('assets/images/social.png');
        background-size: cover;
        background-position: 0;
        float: left;
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
        display: block;
        color: transparent;
        filter: brightness(0.382);
        @for $i from 1 through 7 {
            &:nth-child(#{$i}) {
                background-position: ($i - 1) * -3rem top;
            }
        }
        &:hover {
            filter: brightness(0.1);
        }
    }
}

/* === CTA === */

.cta {
    background-color: $color-dark;
    @include scale(padding-top, padding-bottom);
    @include clearfix;
    .wrap {
        align-items: center;
        @include breakpoint(sm) {
            display: flex;
            justify-content: space-between;
        }
    }
    h3 {
        color: $color-white;
        @include breakpoint(sm) {
            margin-bottom: 0;
        }
    }
}

.overlay {
    position: fixed;
    display: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(black, 0.618);
}

.modal {
    height: 100%;
    top: 0;
    display: block;
    position: fixed;
    overflow: scroll;
    overflow-x: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background-color: $color-dark;
    width: map-get($breakpoints, sm);
    max-width: 100%;
    transform: translate3d(-768px, 0, 0);
    transition: transform 0.3s ease;
    @include clearfix;
    &.active {
        transform: translate3d(0, 0, 0);
    }
    .material-icons {
        position: absolute;
        top: 7.5vw;
        left: 100%;
        margin-left: calc( -5vw + -1em);
        z-index: 1000;
        cursor: pointer;
        color: $color-white;
        font-size: 3rem;
        transition: transform 0.2s ease;
        background-color: transparent;
        padding: 0;
        text-decoration: none;
        @include breakpoint(sm) {
            top: 6rem;
            left: map-get($breakpoints, sm);
            margin-left: calc(-1em + -6rem);
        }
        &:hover {
            transform: rotate(-90deg);
        }
    }
    form {
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 100%;
        @include scale(padding, margin);
    }
    h3,
    .gfield_label,
    .gfield_description,
    label {
        color: $color-white;
    }
    .gfield {
        @include breakpoint(md) {
            margin-bottom: 1.5em;
        }
    }
    input:not([type="submit"]),
    select,
    textarea {
        border: 2px solid $color-white;
        background-color: rgba($color-white, 0.1);
        color: $color-white;
        font-size: 1.5rem;
    }
    textarea {
        height: 12rem;
    }
     ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba( $color-white, 0.82);
    }
     ::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba( $color-white, 0.82);
    }
     :-ms-input-placeholder {
        /* IE 10+ */
        color: rgba( $color-white, 0.82);
    }
     :-moz-placeholder {
        /* Firefox 18- */
        color: rgba( $color-white, 0.82);
    }
}